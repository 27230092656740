

html, body { height: 100%; }
body { 
  margin: 0; 
  // font-family: Roboto, "Helvetica Neue", sans-serif; 
  font-family: 'Poppins', sans-serif !important;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.special-date {
  background-color:  #348db2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: rgba(63, 81, 181, 0.549);
  border-radius: 10px;
  background:linear-gradient(180deg, #6FAFFB 0%, #1A83FF 100%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: rgba(63, 81, 181, 0.549);
  background: linear-gradient(180deg, #6FAFFB 0%, #1A83FF 100%);
}
@import '@angular/material/prebuilt-themes/indigo-pink.css';
.mat-typography{
  font-family: 'Poppins', sans-serif !important;
}
.mat-list-item{
  font-family: 'Poppins', sans-serif !important;
}
.mat-menu-panel{
  min-height: 44px !important;
}

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// .mat-calendar-controls {
//   display: flex;
//   margin: 0% calc(33% / 7 - 16px)!important;
// }
.mat-calendar-previous-button, .mat-calendar-next-button{
  font-size: 13px !important;
}
.mat-calendar-previous-button::after, .mat-calendar-next-button::after {
  color: #1A83FF;
  margin: 16.5px!important;
}
.mat-button, .mat-icon-button:hover {
  background: #FFFFFF;
}
button:focus {
  outline: 0px !important;
}
.mat-calendar-body-cell-content {
  border-width: 0px!important;
}
.mat-calendar-header {
  padding: 0px 8px 0 8px!important;
}
.fc table {
  font-size: 12px !important;
  font-family: 'Poppins', sans-serif !important;
  border-radius: 5px 5px 0px 0px!important;
}
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  font-size: 13px;
  letter-spacing: 0.10px;
}
.fc .fc-daygrid-day-number {
  font-size: 12px !important;
}
.fc-col-header {
  background: #2D9FFA !important;
  color: white;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
  align-items: center;
  letter-spacing: 0.005em;
}
.fc .fc-button-primary:disabled {
  background-color: #2D9FFA!important;
  border-right: 0.5px solid #EFEFEF;
  box-shadow: 0px 4px 10px rgba(45, 159, 250, 0.25);
  border-radius: 4px;
  color: white !important;
  border-color: #2D9FFA !important;
}
.fc .fc-today-button {
  background-color: #2D9FFA!important;
  border-right: 0.5px solid #EFEFEF;
  box-shadow: 0px 4px 10px rgba(45, 159, 250, 0.25);
  border-radius: 4px;
  color: white !important;
  border-color: #2D9FFA !important;
  padding: 4px 8px !important;
  font-family: 'Poppins', sans-serif !important;
}
.fc .fc-prev-button,.fc-dayGridMonth-button,.fc-next-button{
  background-color: #005BC7!important;
  border-right: 0.5px solid #EFEFEF;
  box-shadow: 0px 4px 10px rgba(0, 91, 199, 0.15);
  border-radius: 4px 0px 0px 4px;
  color: white !important;
  border-color: #005BC7 !important;
  padding: 4px 8px !important;
}
.fc .fc-dayGridMonth-button{
  font-family: 'Poppins', sans-serif !important;
}
.fc .fc-next-button:focus {
  box-shadow:0 0 0 0 #448BDE !important;
}
.fc .fc-prev-button:focus {
  box-shadow:0 0 0 0 #448BDE !important;
}
.fc .fc-dayGridMonth-button:focus {
  box-shadow:0 0 0 0 #448BDE !important;
}
.fc .fc-today-button:focus {
  box-shadow:0 0 0 0 #448BDE !important;
}
.fc .fc-next-button:hover {
  background: #448BDE !important;
  border-right: 0.5px solid #EFEFEF;
  box-shadow: 0px 4px 10px rgba(0, 91, 199, 0.15);
  border-radius: 0px 4px 4px 0px;
  color: white !important;
  border-color: #448BDE !important;
}
.fc .fc-prev-button:hover {
  background: #448BDE !important;
  border-right: 0.5px solid #EFEFEF;
  box-shadow: 0px 4px 10px rgba(0, 91, 199, 0.15);
  border-radius: 4px 0px 0px 4px;
  color: white !important;
  border-color: #448BDE !important;
}
.fc .fc-dayGridMonth-button:hover {
  background: #448BDE !important;
  border-right: 0.5px solid #EFEFEF;
  box-shadow: 0px 4px 10px rgba(0, 91, 199, 0.15);
  border-radius: 4px;
  color: white !important;
  border-color: #448BDE !important;
}
.fc .fc-toolbar-title {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 38px;
  letter-spacing: 0.005em;
  color: #000000;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 10px !important;
}



.no-border {
  border: 0 !important;
  margin: 1em auto!important;
}
.swal2-styled.swal2-confirm{
  background: linear-gradient(180deg, #3A94FF 0%, #006BEA 100%)!important;
  border-radius: 5px !important;
  font-size: 16px!important;
}
.swal2-styled:focus {
  box-shadow: 0 0 0 0px #fff, 0 0 0 0px rgb(67 172 219)!important;
}
.swal2-styled.swal2-cancel {
  background: linear-gradient(180deg, #FF7629 0%, #FF3F3F 100%)!important;
  border-radius: 5px!important;
  font-size: 16px!important;
}
.swal2-title{
  color: #000000!important;
}
.swal2-popup {
  width: 28em !important;
}